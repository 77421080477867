<template>
    <v-row>
      <!-- header -->
      <mini-header :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :addPadding="true"></mini-header>
      <!-- end header -->
      <v-row class="mt-16" align="center">
        <v-empty-state title="En fase de creación" type="create" id="tiendas" customClass="mt-7" :height="300" :hiddenDescription="true" :isFree="true"/>
      </v-row>
    </v-row>
  </template>
  <script>
  import VEmptyState from '@/components/commons/VEmptyState'
  import MiniHeader from '@/components/commons/MiniHeader'

  export default {
    components: {
      VEmptyState,
      MiniHeader
    },
    data: () => ({
      breadcrumbs: {
       main: 'Reclamación',
       children: []
     }
    })
  }
  </script>